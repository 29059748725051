@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  /* FaktPro Thin */
  @font-face {
    font-family: 'FaktPro';
    font-weight: 100;
    src: local('FaktPro'), url('./fonts/FaktPro/FaktProThin.otf') format('opentype');
  }

  @font-face {
    font-family: 'FaktPro';
    font-weight: 100;
    font-style: italic;
    src: local('FaktPro'), url('./fonts/FaktPro/FaktProThinItalic.otf') format('opentype');
  }

  /* FaktPro Light */
  @font-face {
    font-family: 'FaktPro';
    font-weight: 300;
    src: local('FaktPro'), url('./fonts/FaktPro/FaktProLight.otf') format('opentype');
  }

  @font-face {
    font-family: 'FaktPro';
    font-weight: 300;
    font-style: italic;
    src: local('FaktPro'), url('./fonts/FaktPro/FaktProLightItalic.otf') format('opentype');
  }

  /* FaktPro Normal */
  @font-face {
    font-family: 'FaktPro';
    font-weight: 400;
    src: local('FaktPro'), url('./fonts/FaktPro/FaktProNormal.otf') format('opentype');
  }

  @font-face {
    font-family: 'FaktPro';
    font-weight: 400;
    font-style: italic;
    src: local('FaktPro'), url('./fonts/FaktPro/FaktProNormalItalic.otf') format('opentype');
  }

  /* FaktPro Medium */
  @font-face {
    font-family: 'FaktPro';
    font-weight: 500;
    src: local('FaktPro'), url('./fonts/FaktPro/FaktProMedium.otf') format('opentype');
  }

  @font-face {
    font-family: 'FaktPro';
    font-weight: 500;
    font-style: italic;
    src: local('FaktPro'), url('./fonts/FaktPro/FaktProMediumItalic.otf') format('opentype');
  }

  /* FaktPro SemiBold */
  @font-face {
    font-family: 'FaktPro';
    font-weight: 600;
    src: local('FaktPro'), url('./fonts/FaktPro/FaktProSemiBold.otf') format('opentype');
  }

  @font-face {
    font-family: 'FaktPro';
    font-weight: 600;
    font-style: italic;
    src: local('FaktPro'), url('./fonts/FaktPro/FaktProSemiBoldItalic.otf') format('opentype');
  }

  /* FaktPro Bold */
  @font-face {
    font-family: 'FaktPro';
    font-weight: 700;
    src: local('FaktPro'), url('./fonts/FaktPro/FaktProBold.otf') format('opentype');
  }

  @font-face {
    font-family: 'FaktPro';
    font-weight: 700;
    font-style: italic;
    src: local('FaktPro'), url('./fonts/FaktPro/FaktProBoldItalic.otf') format('opentype');
  }

  /* FaktPro Black */
  @font-face {
    font-family: 'FaktPro';
    font-weight: 900;
    src: local('FaktPro'), url('./fonts/FaktPro/FaktProBlack.otf') format('opentype');
  }

  @font-face {
    font-family: 'FaktPro';
    font-weight: 900;
    font-style: italic;
    src: local('FaktPro'), url('./fonts/FaktPro/FaktProBlackItalic.otf') format('opentype');
  }
}

.root {
  max-width: 100%;
}

*::-webkit-scrollbar {
  width: 6px;               /* width of the entire scrollbar */
  height: 6px;
}

*::-webkit-scrollbar-track {
  background: #94a3b8;        /* color of the tracking area */
}

*::-webkit-scrollbar-thumb {
  background-color: #64748b;    /* color of the scroll thumb */
}

.hide-scroll::-webkit-scrollbar, .react-horizontal-scrolling-menu--scroll-container::-webkit-scrollbar {
  display: none;
}

.hide-scroll, .react-horizontal-scrolling-menu--scroll-container {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.react-horizontal-scrolling-menu--wrapper {
  min-width: 0;
} 

.bg-stripes-blue {
  background-color: #60a5fa1a;
  background-image: linear-gradient(
    135deg,#3b82f680 10%,transparent 0,transparent 50%,#3b82f680 0,#3b82f680 60%,transparent 0,transparent);
  background-size: 7.07px 7.07px;
}

@keyframes border-pulsate {
  0%   { border-color: rgba(255, 255, 255, 1); }
  50% { border-color: rgba(255, 255, 255, 0);  }
  100%   { border-color: rgba(255, 255, 255, 1);  }
}

.popup-overlay {
  background-color: rgba(0, 0, 0, 0.75);
}
